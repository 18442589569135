import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getAxios, getUrlAxios } from '../hooks/useAxios';
import {
	transformDataBoutique,
	transformDataRaison,
	transformDataUser,
} from '../common/function/transformData';

const DataContext = createContext();

const DataProvider = ({ children }) => {
	const [data, setData] = useState({ users: [], boutiques: [], conversion: [], raisons: [] });

	useEffect(() => {
		const fetchData = async () => {
			try {
				const [user, shopsList, conversions, raison, conversionsNoArgsites] = await Promise.all([
					getAxios('users', { headers: { Accept: 'application/json' } }),
					getAxios(`plateforms?time=${Date.now()}`, {
						headers: { Accept: 'application/json' },
					}),
					getUrlAxios('listConversion'),
					getAxios('extension_reason_uninstalls', {
						headers: { Accept: 'application/json' },
					}),
					getUrlAxios('conversionNoArgsite'),
				]);
				const boutiques = transformDataBoutique(shopsList);

				const users = transformDataUser(user);
				const conversion = conversions.conversion || [];
				const conversionNoArgsite = conversionsNoArgsites.conversion || [];
				const raisons = transformDataRaison(raison);
				setData({ ...data, users, boutiques, conversion, raisons, conversionNoArgsite });
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, []);
	const updateConversion = (newdata) => {
		setData({ ...data, conversion: newdata });
	};

	return (
		// eslint-disable-next-line react/jsx-no-constructed-context-values
		<DataContext.Provider value={{ data, updateConversion }}>{children}</DataContext.Provider>
	);
};

DataProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export { DataContext, DataProvider };
