import React, { Suspense } from 'react';
import PageWrapper from '../PageWrapper/PageWrapper';
import ContentRoutes from './ContentRoutes';
import Spinner from '../../components/bootstrap/Spinner';
import Page from '../Page/Page';

const _loading = (
	<PageWrapper>
		<Page>
			<div
				className='d-flex justify-content-center align-items-center'
				style={{ height: '100vh' }}>
				<Spinner animation='grow' style={{ width: '5rem', height: '5rem' }} />
			</div>
		</Page>
	</PageWrapper>
);

const Content = () => {
	return (
		<main className='content'>
			<Suspense fallback={_loading}>
				<ContentRoutes />
			</Suspense>
		</main>
	);
};

export default Content;
