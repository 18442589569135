import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';

const User = ({ user, logout }) => {
	const navigate = useNavigate();
	return (
		<Dropdown>
			<DropdownToggle hasIcon={false}>
				<div className='user'>
					<div className='user-info'>
						<div className='user-name'>{user.username}</div>
					</div>
				</div>
			</DropdownToggle>
			<DropdownMenu>
				<DropdownItem>
					<Button icon='AccountBox' onClick={() => navigate(`../admin/${user.id}`)}>
						Profile
					</Button>
				</DropdownItem>
				<DropdownItem isDivider />
				<DropdownItem>
					<Button
						icon='Logout'
						onClick={() => {
							logout();
						}}>
						Logout
					</Button>
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};
User.propTypes = {
	logout: PropTypes.func,
	user: PropTypes.shape({
		roles: PropTypes.arrayOf(PropTypes.string),
		username: PropTypes.string,
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}),
};
User.defaultProps = {
	user: null,
	logout: null,
};

export default User;
