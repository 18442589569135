import axios from 'axios';

const API_BASE_URL = `${process.env.REACT_APP_API_URL}api/`;

const api = axios.create({
	baseURL: API_BASE_URL,
});
const apiUrl = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/`,
});

export const getUrlAxios = async (url, config) => {
	const response = await apiUrl.get(url, config);
	return response.data;
};

export const getAxios = async (url, config) => {
	const response = await api.get(url, config);
	return response.data;
};

export const postAxios = async (url, data) => {
	const response = await api.post(url, data);
	return response.data;
};
export const putAxios = async (url, data) => {
	const response = await api.put(url, data);
	return response.data;
};
export const deleteAxios = async (url) => {
	const response = await api.delete(url);
	return response.data;
};

export const postAxiosJwt = async (url, data, token) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		'Content-Type': 'application/json',
	};
	const response = await api.post(url, data, { headers });
	return response.data;
};
export const putAxiosJwt = async (url, data, token) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		'Content-Type': 'application/json',
	};
	const response = await api.put(url, data, { headers });
	return response.data;
};

export const getAxiosJwt = async (url, token) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		'Content-Type': 'application/json',
	};
	const response = await api.get(url, { headers });
	return response.data;
};

export const deleteAxiosJwt = async (url, token) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		'Content-Type': 'application/json',
	};
	const response = await api.delete(url, { headers });
	return response.data;
};
