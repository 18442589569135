import { useState } from 'react';

export default function useToken() {
	const getToken = () => {
		const tokenString = localStorage.getItem('token_admin');
		const token = JSON.parse(tokenString);
		return token;
	};

	const [token, setToken] = useState(getToken());
	const saveToken = (userToken) => {
		localStorage.setItem('token_admin', JSON.stringify(userToken));
		setToken(userToken);
	};
	return {
		setToken: saveToken,
		token,
	};
}
