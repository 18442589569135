export function test() {
	return null;
}

export function getOS() {
	const { userAgent } = window.navigator;
	const { platform } = window.navigator;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
	let os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'MacOS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	document.documentElement.setAttribute('os', os);
	return os;
}

export const hasNotch = () => {
	/**
	 * For storybook test
	 */
	const storybook = window.location !== window.parent.location;
	const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
	const aspect = window.screen.width / window.screen.height;
	const aspectFrame = window.innerWidth / window.innerHeight;
	return (
		(iPhone && aspect.toFixed(3) === '0.462') ||
		(storybook && aspectFrame.toFixed(3) === '0.462')
	);
};

export const mergeRefs = (refs) => {
	return (value) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				ref(value);
			} else if (ref != null) {
				ref.current = value;
			}
		});
	};
};

export const randomColor = () => {
	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];

	const color = Math.floor(Math.random() * colors.length);

	return colors[color];
};

export const priceFormat = (price) => {
	return price.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	});
};

export const average = (array) => array.reduce((a, b) => a + b) / array.length;

export const percent = (value1, value2) => ((value1 / value2 - 1) * 100).toFixed(2);

export const getFirstLetter = (text, letterCount = 2) => {
	if (!text) {
		return '';
	}

	const match = text.toUpperCase().match(/\b(\w)/g);

	if (!match) {
		return '';
	}

	return match.join('').substring(0, letterCount);
};

export const debounce = (func, wait = 1000) => {
	let timeout;

	return function executedFunction(...args) {
		const later = () => {
			clearTimeout(timeout);
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};
export const formatDateToFrench = (dateString) => {
	const options = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
	};
	const date = new Date(dateString);

	// Vérifier si la date est valide
	if (Number.isNaN(date.getTime()) || date.toString() === 'Invalid Date') {
		return 'Date invalide';
	}

	return date.toLocaleDateString('fr-FR', options);
};
export const formatDateToFrenchMini = (dateString) => {
	const options = {
		month: 'long',
		day: 'numeric',
	};
	const date = new Date(dateString);

	// Vérifier si la date est valide
	if (Number.isNaN(date.getTime()) || date.toString() === 'Invalid Date') {
		return 'Date invalide';
	}

	return date.toLocaleDateString('fr-FR', options);
};
export const formatDistanceToNowFrench = (dateString) => {
	if (!dateString || typeof dateString !== 'string') {
		return 'Date invalide';
	}

	const date = new Date(dateString);

	if (Number.isNaN(date.getTime()) || date.toString() === 'Invalid Date') {
		return 'Date invalide';
	}

	const now = new Date();
	const diffInMilliseconds = date - now;

	const seconds = Math.round(Math.abs(diffInMilliseconds) / 1000);
	const minutes = Math.round(seconds / 60);
	const hours = Math.round(minutes / 60);
	const days = Math.round(hours / 24);
	const months = Math.round(days / 30); // Approximation du nombre de jours dans un mois
	const years = Math.round(days / 365); // Approximation du nombre de jours dans une année

	const rtf = new Intl.RelativeTimeFormat('fr', { numeric: 'auto' });

	if (seconds < 60) {
		return rtf.format(-seconds, 'second');
	}
	if (minutes < 60) {
		return rtf.format(-minutes, 'minute');
	}
	if (hours < 24) {
		return rtf.format(-hours, 'hour');
	}
	if (days < 30) {
		return rtf.format(-days, 'day');
	}
	if (months < 12) {
		return rtf.format(-months, 'month');
	}
	return rtf.format(-years, 'year');
};
