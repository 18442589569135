import React, { lazy } from 'react';

const LANDING = {
	BOUTIQUES: lazy(() => import('../pages/dashboard/Boutique/DashboardPage')),
	DASHBOARD: lazy(() => import('../pages/dashboard/Boutique/DashboardPage')),
};
const APP = {
	USER: {
		LIST: lazy(() => import('../pages/dashboard/User')),
		PROFIL: lazy(() => import('../pages/dashboard/ProfilUser')),
	},
};
const RAISON = {
	LIST: lazy(() => import('../pages/desinstall/ListeRaison')),
};

const RETRAIT = {
	DEMANDE: lazy(() => import('../pages/retrait/Demande')),
};

const STAT = {
	STAT: lazy(() => import('../pages/stat/ListStat')),
};
const TAUX = {
	TAUX: lazy(() => import('../pages/tauxEchange/Historique')),
};
const CONVESRION = {
	LIST: lazy(() => import('../pages/conversion/ListConversion')),
};
const PARAMETER = {
	PARAMETER: lazy(() => import('../pages/parameter/Parameter')),
};
const SERVICES = {
	CLIENT: lazy(() => import('../pages/servicesClient/ListMessage')),
};

const presentation = [
	/**
	 * Page
	 */
	{
		path: '/', // dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},
	{
		path: '/boutiques', // dashboardMenu.dashboard.path,
		element: <LANDING.BOUTIQUES />,
		exact: true,
	},
	{
		path: 'raison',
		element: <RAISON.LIST />,
		exact: true,
	},
	{
		path: 'retrait',
		element: <RETRAIT.DEMANDE />,
		exact: true,
	},
	{
		path: 'stat',
		element: <STAT.STAT />,
		exact: true,
	},
	{
		path: 'tauxEchange',
		element: <TAUX.TAUX />,
		exact: true,
	},
	{
		path: 'conversion',
		element: <CONVESRION.LIST />,
		exact: true,
	},
	{
		path: 'parametre',
		element: <PARAMETER.PARAMETER />,
		exact: true,
	},
	{
		path: 'servicesClient',
		element: <SERVICES.CLIENT />,
		exact: true,
	},
	{
		path: '/user',
		element: <APP.USER.LIST />,
		exact: true,
	},
	{
		path: '/admin',
		element: <APP.USER.LIST />,
		exact: true,
	},
	{
		path: '/admin/:id',
		element: <APP.USER.PROFIL />,
		exact: true,
	},
	{
		path: `/user/:id`,
		element: <APP.USER.PROFIL />,
		exact: true,
	},
];

const contents = [...presentation];

export default contents;
