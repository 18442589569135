// import { useContext } from 'react';
// import ThemeContext from '../contexts/themeContext';

export default function useDarkMode() {
	// const { darkModeStatus, setDarkModeStatus } = useContext(ThemeContext);
	const darkModeStatus = false;
	const setDarkModeStatus = false;

	const themeStatus = darkModeStatus ? 'dark' : 'light';

	return { themeStatus, darkModeStatus, setDarkModeStatus };
}
