import React from 'react';
import PropTypes from 'prop-types';
import cashopLogo from '../assets/img/yenbackLogo.svg';

// eslint-disable-next-line no-unused-vars
const Logo = ({ width, height }) => {
	return <img width='140' alt='logo' src={cashopLogo} />;
};

Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;
