import React from 'react';
import { useMeasure } from 'react-use';
// import classNames from 'classnames';
// import useDarkMode from '../../hooks/useDarkMode';

const Footer = () => {
	const [ref, { height }] = useMeasure();

	const root = document.documentElement;
	root.style.setProperty('--footer-height', `${height}px`);

	// const { darkModeStatus } = useDarkMode();

	return (
		<footer ref={ref} className='footer'>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col'>
						<span className='fw-light'>Copyright 2024</span>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
