import React from 'react';

export const transformDataBoutique = (shop) => {
	let imaginfo = '';
	let store = null;
	const boutique = shop.map((el) => {
		// filtre  le boutique par l' affichage
		const filterView = el.shopStatistics.filter(
			(entry) => entry.nom_stat.includes('Affichage') && entry.url_shop,
		);
		// filtre le boutique par l'evenement clique
		const filterClick = el.shopStatistics.filter((entry) => entry.nom_stat.includes('click'));

		const newTransaction = [];
		const apiIdentifiant = [];

		el.transactions.reverse().forEach((tr) => {
			if (!apiIdentifiant.includes(tr.apiIdentifiant)) {
				newTransaction.push(tr);
				apiIdentifiant.push(tr.apiIdentifiant);
			}
		});

		// Créer un tableau contenant les transactions fusionnées avec les statistiques de la boutique
		const transactionMerges = newTransaction.map((transaction) => ({
			nom_stat: 'Conversion',
			identifiant: transaction.account.user.email,
			url_shop: null,
			createdAt: transaction.createdAt,
		}));

		const mergeData = el.shopStatistics.concat(transactionMerges);
		const sortedDataAscending = mergeData.sort(
			(a, b) => new Date(a.createdAt) - new Date(b.createdAt),
		);

		// cree un json structure par date au data filtre par le click
		const groupedDataClick = filterClick.reverse().reduce((result, entry) => {
			const createdAt = entry.createdAt.split('T')[0];
			const user = entry.identifiant;

			result[createdAt] ??= {};
			result[createdAt][user] ??= [];
			result[createdAt][user].push(entry.createdAt.split('T')[1]);

			return result;
		}, {});

		// cree un json structure par date au data filtre par l'affichage
		const groupedDataView = filterView.reverse().reduce((result, entry) => {
			const createdAt = entry.createdAt.split('T')[0];
			const user = entry.identifiant;

			result[createdAt] ??= {};
			result[createdAt][user] ??= [];
			result[createdAt][user].push([entry.url_shop, entry.createdAt.split('T')[1]]);

			return result;
		}, {});

		// cree un json structure par url au data filtre par l'affichage
		const groupedDataViewUrl = filterView.reverse().reduce((result, entry) => {
			const url = entry.url_shop;
			const user = entry.identifiant;
			const date = entry.createdAt;

			if (!result[url]) {
				result[url] = {};
			}

			if (!result[url][user]) {
				result[url][user] = [];
			}
			result[url][user].push(date);

			return result;
		}, {});

		// cree un json structure par utilisateur au data filtre par l'affichage
		const groupedDataViewUser = filterView.reverse().reduce((result, entry) => {
			const url = entry.url_shop;
			const user = entry.identifiant;
			const date = entry.createdAt;

			if (!result[user]) {
				result[user] = {};
			}

			if (!result[user][url]) {
				result[user][url] = [];
			}

			result[user][url].push(date);

			return result;
		}, {});

		const groupedDataUserUnique = sortedDataAscending.reverse().reduce((result, entry) => {
			const url = entry.url_shop;
			const type = entry.nom_stat;
			const user = entry.identifiant;
			const date = entry.createdAt.split('T')[0];
			const heure = entry.createdAt.split('T')[1];

			if (!result[user]) {
				result[user] = {};
			}

			if (!result[user][date]) {
				result[user][date] = [];
			}
			if (!result[user][date][type]) {
				result[user][date][type] = [];
			}

			result[user][date][type].push([url, heure]);

			return result;
		}, {});
		const resultListUserUnique = Object.keys(groupedDataUserUnique).map((user) => ({
			[user]: groupedDataUserUnique[user],
		}));

		const resultListClic = Object.keys(groupedDataClick).map((date) => ({
			[date]: groupedDataClick[date],
		}));
		const resultListView = Object.keys(groupedDataView).map((date) => ({
			[date]: groupedDataView[date],
		}));
		const resultListViewUrl = Object.keys(groupedDataViewUrl).map((url) => ({
			[url]: groupedDataViewUrl[url],
		}));
		const resultListViewUser = Object.keys(groupedDataViewUser).map((url) => ({
			[url]: groupedDataViewUser[url],
		}));

		const reference = el.reference.split('_');
		const casback = el.cashbackCustomerLabelInfo;

		if (casback) {
			store = casback.split('');
			store = store[store.length - 1];
			if (store === '%') {
				store = 'Remise %';
			} else {
				store = 'EUR';
			}
		} else {
			store = null;
		}
		const mystore = store;
		if (el.logoUrl) {
			// eslint-disable-next-line jsx-a11y/alt-text
			const lienImage = `${process.env.REACT_APP_API_URL}${el.logoUrl}`;
			imaginfo = <img src={lienImage} alt='' width={54} height={54} />;
		} else {
			imaginfo = el.name;
		}
		return {
			id: el.id,
			image: imaginfo,
			name: el.name ? el.name : '-',
			domaine: el.domain ? el.domain : '-',
			casback: el.cashbackCustomerLabelInfo ? el.cashbackCustomerLabelInfo : '-',
			store: mystore,
			redirect: el.redirectionUrl,
			description: el.description,
			reference: reference[0] ? reference[0] : '',
			fixeFee: el.fixed_fee,
			isUrl: el.isUrl,
			anomalie: el.anomalie,
			statView: filterView.length,
			statClick: filterClick.length,
			statUser: resultListUserUnique.length,
			transactions: newTransaction,
			detailClick: resultListClic,
			detailView: resultListView,
			detailUrl: resultListViewUrl,
			detailUser: resultListViewUser,
			dataUser: resultListUserUnique,
		};
	});
	return boutique;
};

export const transformDataUser = (user) => {
	const users = user.map((el) => ({
		id: el.id,
		email: el.email ? el.email : '',
		firstName: el.firstName || '',
		lastName: el.lastName || '',
		date: el.subscribedAt,
		roles: el.roles,
		lastConnect: el.lastConnect || el.subscribedAt,
	}));
	return users;
};
export const transformDataRaison = (raison) => {
	const raisons = raison.map((el, i) => ({
		id: i + 1,
		commantaire: el.feedback,
		raison: el.uninstallReason,
	}));
	return raisons;
};
