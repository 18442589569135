import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';

import classNames from 'classnames';
import { useFormik } from 'formik';
import { useToasts } from 'react-toast-notifications';

import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import Toasts from '../../../components/bootstrap/Toasts';
import Spinner from '../../../components/bootstrap/Spinner';

// eslint-disable-next-line react/prop-types
const LoginHeader = () => {
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Bienvenue,</div>
			<div className='text-center h4 text-muted mb-5'>Connectez-vous pour continuer !</div>
		</>
	);
};

const validateLogin = (values) => {
	const errors = {};
	if (!values.validationEmailLogin) {
		errors.validationEmailLogin = 'Required';
	}

	if (!values.validationPasswordLogin) {
		errors.validationPasswordLogin = 'Required';
	}

	return errors;
};

async function loginUser(credentials) {
	return fetch(`${process.env.REACT_APP_API_URL}api/login`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(credentials),
	})
		.then((res) => {
			return res.json();
		})
		.catch((error) => {
			return error;
		});
}

const Login = ({ setToken }) => {
	const { darkModeStatus } = useDarkMode();
	// eslint-disable-next-line no-unused-vars
	const [isLoaded, setIsLoading] = useState(false);
	// const [usernameInput, setUsernameInput] = useState(false);
	const { addToast } = useToasts();

	const formikLogin = useFormik({
		initialValues: {
			validationEmailLogin: '',
			validationPasswordLogin: '',
		},
		validateLogin,
		onSubmit: (values) => {
			// eslint-disable-next-line no-alert
			alert(JSON.stringify(values, null, 2));
		},
	});
	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault(); // Empêcher le comportement par défaut du formulaire
			loginAction(); // Exécuter l'action de connexion
		}
	};

	const loginAction = async () => {
		setIsLoading(true);
		const data = {
			email: formikLogin.values.validationEmailLogin,
			password: formikLogin.values.validationPasswordLogin,
		};
		const response = await loginUser(data);
		if (response.token) {
			const userInfo = jwtDecode(response.token);
			const role = userInfo.roles;
			if (role.includes('ROLE_ADMIN')) {
				setToken(response.token);
			} else {
				addToast(
					<Toasts
						title='Toasts notifications'
						icon='Cancel'
						iconColor='danger'
						time='Just now'
						isDismiss={false}>
						Vous n'êtes pas autorisé à accéder à ce site <br />
					</Toasts>,
					{
						autoDismiss: true,
					},
				);
			}
			setIsLoading(false);
			formikLogin.resetForm();
		} else {
			addToast(
				<Toasts
					title='Toasts notifications'
					icon='Cancel'
					iconColor='danger'
					time='Just now'
					isDismiss={false}>
					Une erreur est survenue <br />
					{response.message}
				</Toasts>,
				{
					autoDismiss: true,
				},
			);
			setIsLoading(false);
		}
	};

	return (
		<PageWrapper title='Login' className='bg-primary'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody className='mb-5 mt-2'>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<Logo width={200} />
									</Link>
								</div>

								<LoginHeader />

								<form className='row g-4 m-4'>
									<div className='col-12'>
										<FormGroup
											id='validationEmailLogin'
											isFloating
											label='Votre email'>
											<Input
												onChange={formikLogin.handleChange}
												onBlur={formikLogin.handleBlur}
												value={formikLogin.values.validationEmailLogin}
												isValid={formikLogin.isValid}
												isTouched={formikLogin.touched.validationEmailLogin}
												invalidFeedback={
													formikLogin.errors.validationEmailLogin
												}
												onKeyPress={handleKeyPress}
											/>
										</FormGroup>
										<br />
										<FormGroup
											id='validationPasswordLogin'
											isFloating
											label='Mot de passe'>
											<Input
												type='password'
												onChange={formikLogin.handleChange}
												onBlur={formikLogin.handleBlur}
												value={formikLogin.values.validationPasswordLogin}
												isValid={formikLogin.isValid}
												isTouched={
													formikLogin.touched.validationPasswordLogin
												}
												invalidFeedback={
													formikLogin.errors.validationPasswordLogin
												}
												onKeyPress={handleKeyPress}
											/>
										</FormGroup>
									</div>

									<div className='col-12 mt-4'>
										{isLoaded ? (
											<Button disabled color='warning' className='w-100 py-3'>
												<Spinner /> Connect
											</Button>
										) : (
											<Button
												disabled={
													!(
														formikLogin.isValid &&
														formikLogin.dirty &&
														!isLoaded
													)
												}
												color='warning'
												className='w-100 py-3'
												onClick={() => loginAction()}>
												Connecter
											</Button>
										)}
									</div>
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								target='_blank'
								rel='noreferrer'
								href='https://yenback.com/donnees_personnelles'
								className={classNames('text-decoration-none me-3 link-light')}>
								Politique de confidentialité
							</a>
							<a
								rel='noreferrer'
								target='_blank'
								href='https://yenback.com/cgu'
								className={classNames(
									'link-light text-decoration-none link-light',
								)}>
								Conditions d'utilisation
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	// eslint-disable-next-line react/require-default-props
	setToken: PropTypes.func,
};
Login.defaultProps = {};

export default Login;
